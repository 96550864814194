@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap");

body {
  color: #fff;
}

.mint-page {
  background-image: url("./assets/img/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 3rem;
}

.navb {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 60px;
}

.cusbtn {
  background-color: #2a2d36;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  letter-spacing: 3px;
}

.cusbtn:hover {
  color: #fff;
}

.mintbutton {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.mint {
  margin-top: 3rem;
  background-color: #161f23d0;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 10px 1px #ccc;
}
.mih1 {
  font-weight: bold;
  font-size: 3rem;
  letter-spacing: 10px;
  font-family: "Exo", sans-serif;
}
.subtitle {
  font-size: 1.3rem;
  letter-spacing: 3px;
  font-family: "Exo", sans-serif;
  font-weight: 400;
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #000;
  background-color: #fff;
  height: 70px;
  width: 70px;
  border-radius: 4px;
}
.number {
  font-weight: bold;
  font-size: 1.3rem;
}

.si {
  height: 30px;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .logo {
    text-align: center;
    height: 60px !important;
  }
  .mint-page {
    height: auto !important;
  }
  .rightlogo {
    margin-top: 5rem;
  }

  .navb {
    flex-direction: column;
    justify-content: center;
  }
  .rmt {
    margin-top: 1rem;
  }
}
